<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col xl="12">
        <b-card header="Información de la Propiedad">
          <b-form>
            <app-tipo-propiedad
              label="Tipo de propiedad"
              :labelCols="labelCols"
              :nombre="tipoPropiedadNombre"
              :valido="tipoPropiedadValido"
              :lista="tiposPropiedadesList"
              :msjInvalido="tipoPropiedadInvalidoMsj"
              @validate="validate"
              @updated="tipoPropiedadActualizado">
            </app-tipo-propiedad>

            <b-form-group
              label="Titulo"
              label-for="titulo"
              :label-cols="labelCols">
              <b-form-input 
                id="titulo"
                v-model.trim="$v.titulo.$model"
                :state="tituloValido"
                type="text"
                @blur.native="validate">
              </b-form-input>
              <b-form-invalid-feedback id="tituloFeedback">
                {{ tituloInvalidoMsj }}
              </b-form-invalid-feedback>          
            </b-form-group>

            <!-- <b-form-group
              label="Descripcion"
              label-for="descripcion"
              :label-cols="labelCols">
              <b-form-input 
                id="descripcion"
                v-model.trim="$v.descripcion.$model"
                :state="descripcionValida"
                type="text"
                @blur.native="validate">
              </b-form-input>
              <b-form-invalid-feedback id="descripcionFeedback">
                {{ descripcionInvalidaMsj }}
              </b-form-invalid-feedback>          
            </b-form-group> -->

            <b-form-group
              label="Descripción"
              label-for="descripcion"
              :label-cols="labelCols">
              <b-form-textarea
                id="descripcion"
                v-model.trim="$v.descripcion.$model"
                :state="descripcionValida"
                rows="5"
                max-rows="5">
              </b-form-textarea>
              <b-form-invalid-feedback id="descripcionFeedback">
                {{ descripcionInvalidaMsj }}
              </b-form-invalid-feedback>          
            </b-form-group>

            <b-form-group
              label="Dirección"
              label-for="direccion"
              :label-cols="labelCols">
              <b-form-input 
                id="direccion"
                v-model.trim="$v.direccion.$model"
                :state="direccionValida"
                type="text"
                @blur.native="validate">
              </b-form-input>
              <b-form-invalid-feedback id="direccionFeedback">
                {{ direccionInvalidaMsj }}
              </b-form-invalid-feedback>          
            </b-form-group>

            <b-form-group
              label="Localidad"
              label-for="localidad"
              :label-cols="labelCols">
              <b-form-input 
                id="localidad"
                v-model.trim="$v.localidad.$model"
                :state="localidadValida"
                type="text"
                @blur.native="validate">
              </b-form-input>
              <b-form-invalid-feedback id="localidadFeedback">
                {{ localidadInvalidaMsj }}
              </b-form-invalid-feedback>          
            </b-form-group>

            <b-form-group
              label="Barrio"
              label-for="barrio"
              :label-cols="labelCols">
              <b-form-input 
                id="barrio"
                v-model.trim="$v.barrio.$model"
                :state="barrioValido"
                type="text"
                @blur.native="validate">
              </b-form-input>
              <b-form-invalid-feedback id="barrioFeedback">
                {{ barrioInvalidoMsj }}
              </b-form-invalid-feedback>          
            </b-form-group>

            <div class="grupo-controles-location">
              <b-form-group
                label="Ubicación"
                label-for="location"
                :label-cols="labelCols"
                description="Latitud + Longitud">
                <b-form-input 
                  id="locationLat"
                  v-model.trim="$v.locationLat.$model"
                  :state="locationLatValida"
                  type="text"
                  @blur.native="validate"/>
                <b-form-input 
                  id="locationLng"
                  v-model.trim="$v.locationLng.$model"
                  :state="locationLngValida"
                  type="text"
                  @blur.native="validate"/>
                <b-form-invalid-feedback id="locationFeedback">
                  {{ locationInvalidaMsj }}
                </b-form-invalid-feedback>          
              </b-form-group>
            </div>

            <!-- <b-form-group
              label="Lat."
              label-for="locationLat"
              :label-cols="labelCols">
              <b-form-input 
                id="locationLat"
                v-model.trim="$v.locationLat.$model"
                :state="locationLatValida"
                type="text"
                @blur.native="validate">
              </b-form-input>
              <b-form-invalid-feedback id="locationLatFeedback">
                {{ locationLatInvalidaMsj }}
              </b-form-invalid-feedback>          
            </b-form-group>

            <b-form-group
              label="Lng."
              label-for="locationLng"
              :label-cols="labelCols">
              <b-form-input 
                id="locationLng"
                v-model.trim="$v.locationLng.$model"
                :state="locationLngValida"
                type="text"
                @blur.native="validate">
              </b-form-input>
              <b-form-invalid-feedback id="locationLngFeedback">
                {{ locationLngInvalidaMsj }}
              </b-form-invalid-feedback>          
            </b-form-group> -->

            <b-form-group
              label="Sup.Total"
              label-for="superficieTotal"
              :label-cols="labelCols"
              description="Superficie en m2">
              <b-form-input 
                id="superficieTotal"
                v-model.trim="$v.superficieTotal.$model"
                :state="superficieTotalValida"
                type="text"
                @blur.native="validate">
              </b-form-input>
              <b-form-invalid-feedback id="superficieTotalFeedback">
                {{ superficieTotalInvalidaMsj }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label="Sup.Cubierta"
              label-for="superficieCubierta"
              :label-cols="labelCols"
              description="Superficie en m2">
              <b-form-input 
                id="superficieCubierta"
                v-model.trim="$v.superficieCubierta.$model"
                :state="superficieCubiertaValida"
                type="text"
                @blur.native="validate">
              </b-form-input>
              <b-form-invalid-feedback id="superficieCubiertaFeedback">
                {{ superficieCubiertaInvalidaMsj }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label-for="aEstrenar"
              :label-cols="labelCols">
              <b-form-checkbox 
                  id="aEstrenar"
                  v-model="aEstrenar"
                  value= true
                  unchecked-value= false>
                  A Estrenar
              </b-form-checkbox>
            </b-form-group>

            <b-form-group
              label="Antiguedad"
              label-for="antiguedad"
              :label-cols="labelCols"
              description="Antigüedad en años">
              <b-form-input 
                id="antiguedad"
                v-model.trim="$v.antiguedad.$model"
                :state="antiguedadValida"
                type="text"
                @blur.native="validate">
              </b-form-input>
              <b-form-invalid-feedback id="antiguedadFeedback">
                {{ antiguedadInvalidaMsj }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label="Ambientes"
              label-for="ambientes"
              :label-cols="labelCols">
              <b-form-input 
                id="ambientes"
                v-model.trim="$v.ambientes.$model"
                :state="ambientesValidos"
                type="text"
                @blur.native="validate">
              </b-form-input>
              <b-form-invalid-feedback id="ambientesFeedback">
                {{ ambientesInvalidosMsj }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label="Dormitorios"
              label-for="dormitorios"
              :label-cols="labelCols">
              <b-form-input 
                id="dormitorios"
                v-model.trim="$v.dormitorios.$model"
                :state="dormitoriosValidos"
                type="text"
                @blur.native="validate">
              </b-form-input>
              <b-form-invalid-feedback id="dormitoriosFeedback">
                {{ dormitoriosInvalidosMsj }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label="Baños"
              label-for="banios"
              :label-cols="labelCols">
              <b-form-input 
                id="banios"
                v-model.trim="$v.banios.$model"
                :state="baniosValidos"
                type="text"
                @blur.native="validate">
              </b-form-input>
              <b-form-invalid-feedback id="baniosFeedback">
                {{ baniosInvalidosMsj }}
              </b-form-invalid-feedback>
            </b-form-group>

            <div class="grupo-controles-precio">
              <b-form-group
                label="Precio Venta"
                label-for="venta"
                :label-cols="2"
                horizontal>

                <b-form-select 
                  v-model="$v.monedaVenta.$model" 
                  :options="monedas" 
                  :state="monedaVentaValida"
                  class="mb-3"
                  @blur.native="monedaVentaActualizada">
                </b-form-select>

                <!-- <b-form-input 
                  id="monedaVenta"
                  v-model.trim= "$v.monedaVenta.$model" 
                  :state="monedaVentaValida"
                  type="text"
                  @blur.native="validate">
                </b-form-input> -->

                <b-form-input 
                  id="precioVenta"
                  v-model.trim= "$v.precioVenta.$model" 
                  :state="precioVentaValido"
                  type="text"
                  @blur.native="validate">
                </b-form-input>
                <b-form-invalid-feedback id="precioVentaFeedback">
                  {{ precioVentaInvalidoMsj }}
                </b-form-invalid-feedback>          
              </b-form-group>
            </div>

            <div class="grupo-controles-precio">
              <b-form-group
                label="Precio Alquiler"
                label-for="alquiler"
                :label-cols="2"
                horizontal>

                <b-form-select 
                  v-model="$v.monedaAlquiler.$model" 
                  :options="monedas" 
                  :state="monedaAlquilerValida"
                  class="mb-3"
                  @blur.native="monedaAlquilerActualizada">
                </b-form-select>

                <!-- <b-form-input 
                  id="monedaAlquiler"
                  v-model.trim= "$v.monedaAlquiler.$model" 
                  :state="monedaAlquilerValida"
                  type="text"
                  @blur.native="validate">
                </b-form-input> -->

                <b-form-input 
                  id="precioAlquiler"
                  v-model.trim= "$v.precioAlquiler.$model" 
                  :state="precioAlquilerValido"
                  type="text"
                  @blur.native="validate">
                </b-form-input>
                <b-form-invalid-feedback id="precioAlquilerFeedback">
                  {{ precioAlquilerInvalidoMsj }}
                </b-form-invalid-feedback>          
              </b-form-group>
            </div>

            <b-form-group
              label="Expensas"
              label-for="expensas"
              :label-cols="labelCols">
              <b-form-input 
                id="expensas"
                v-model.trim="$v.expensas.$model"
                :state="expensasValidas"
                type="text"
                @blur.native="validate">
              </b-form-input>
              <b-form-invalid-feedback id="expensasFeedback">
                {{ expensasInvalidasMsj }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label-for="destacada"
              :label-cols="labelCols">
              <b-form-checkbox 
                  id="destacada"
                  v-model="destacada"
                  :value="true"
                  :unchecked-value="false">
                  Destacada
              </b-form-checkbox>
            </b-form-group>

            <b-form-group
              label-for="publicada"
              :label-cols="labelCols">
              <b-form-checkbox 
                  id="publicada"
                  v-model="publicada"
                  :value="true"
                  :unchecked-value="false">
                  Publicada
              </b-form-checkbox>
            </b-form-group>

            <b-form-group
              label-for="alquilada"
              :label-cols="labelCols">
              <b-form-checkbox 
                  id="alquilada"
                  v-model="alquilada"
                  :value="true"
                  :unchecked-value="false">
                  Alquilada
              </b-form-checkbox>
            </b-form-group>

            <b-form-group
              label-for="vendida"
              :label-cols="labelCols">
              <b-form-checkbox 
                  id="vendida"
                  v-model="vendida"
                  :value="true"
                  :unchecked-value="false">
                  Vendida
              </b-form-checkbox>
            </b-form-group>

          </b-form>
        </b-card>
        <b-card header="Características de la Propiedad">
          <b-form>
            <!-- <template v-for="{ nombre, checked, key } in caracteristicas"> -->
            <template v-for="caracteristica, key in caracteristicas">
              <b-form-group
                :key="key"
                :label-cols="1">
                <b-form-checkbox 
                    v-model="caracteristica.checked"
                    :value="true"
                    :unchecked-value="false">
                    {{ caracteristica.nombre }}
                </b-form-checkbox>

                <!-- <b-form-checkbox 
                    :v-model="checked"
                    :value="true"
                    :unchecked-value="false"
                    @input="actualizarCaracteristica({ 'nombre': nombre, 'checked': checked })">
                    {{ nombre }}
                </b-form-checkbox> -->
              </b-form-group>
            </template>
          </b-form>
        </b-card>
      </b-col>
    </b-row>

    <app-a-b-m-button
      @save="save"
      @cancel="goBack">
    </app-a-b-m-button>

    <app-modal-msj
      :visible="modalMsjVisible"
      :mensaje="modalMensaje"
      :variant="modalVariant"
      @okAction="okAction"
      @hiddenAction="hiddenAction">
    </app-modal-msj>
  </div>
</template>

<script>
import TipoPropiedad from '../shared/abmSelect.vue';
import ABMButtons from '../shared/abmButtons.vue';
import { required, requiredIf, maxLength, numeric, decimal, minValue } from 'vuelidate/lib/validators';

import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "propiedad/getField",
  mutationType: "propiedad/updateField",
});

export default {
  name: 'PropiedadesABM',
  components: {
    appTipoPropiedad: TipoPropiedad,
    appABMButton: ABMButtons,
  },
  data () {
    return {
      noValidar: true,
      tipoPropiedadValido: null,
      tipoPropiedadInvalidoMsj: '',
      tituloValido: null,
      tituloInvalidoMsj: '',
      descripcionValida: null,
      descripcionInvalidaMsj: '',
      direccionValida: null,
      direccionInvalidaMsj: '',
      localidadValida: null,
      localidadInvalidaMsj: '',
      barrioValido: null,
      barrioInvalidoMsj: '',
      locationLatValida: null,
      // locationLatInvalidaMsj: '',
      locationLngValida: null,
      // locationLngInvalidaMsj: '',
      locationInvalidaMsj: '',
      superficieTotalValida: null,
      superficieTotalInvalidaMsj: '',
      superficieCubiertaValida: null,
      superficieCubiertaInvalidaMsj: '',
      antiguedadValida: null,
      antiguedadInvalidaMsj: '',
      ambientesValidos: null,
      ambientesInvalidosMsj: '',
      dormitoriosValidos: null,
      dormitoriosInvalidosMsj: '',
      baniosValidos: null,
      baniosInvalidosMsj: '',
      monedaVentaValida: null,
      precioVentaValido: null,
      precioVentaInvalidoMsj: '',
      monedaAlquilerValida: null,
      precioAlquilerValido: null,
      precioAlquilerInvalidoMsj: '',
      expensasValidas: null,
      expensasInvalidasMsj: '',

      labelCols: 2,

      tiposPropiedades: [],
      tiposPropiedadesList: [],
      monedas: []
    }
  },
  computed: {
    ...mapFields([
	    'tipoPropiedad',
	    'tipoPropiedadNombre',
	    'titulo',
	    'descripcion',
	    'direccion',
	    'localidad',
	    'barrio',
	    'locationLat',
      'locationLng',
	    'superficieTotal',
	    'superficieCubierta',
	    'antiguedad',
	    'ambientes',
	    'dormitorios',
	    'banios',
	    'monedaVenta',
	    'precioVenta',
	    'monedaAlquiler',
	    'precioAlquiler',
	    'expensas',
      'aEstrenar',
      'destacada',
      'publicada',
      'alquilada',
      'vendida',

      'caracteristicas',
    ]),
  },
  created() {
    let propiedadId = '';
    if (this.$route.params){
      if (this.$route.params.id){
        propiedadId = this.$route.params.id;
      }
    }

    // this.tiposPropiedades = ['Casa', 'Cochera', 'Departamento', 'Duplex', 'Local', 'Oficina', 'Terreno'];
    this.getDatosAdicionales();

    this.showSpinner();
    this.$store.dispatch('propiedad/getPropiedad', propiedadId)
      .catch( error => this.showErrorMsj (error))
      .finally(() => this.hideSpinner());
  },
  methods: {
    getDatosAdicionales() {
      this.monedas = ['', '$', 'U$S'];

      this.$store.dispatch('tiposPropiedades/getList')
        .then(() => {
          this.tiposPropiedades = this.$store.getters['tiposPropiedades/list'];
          this.tiposPropiedadesList = this.$store.getters['tiposPropiedades/selectList'];
        })
        .catch(error => this.showErrorMsj(error));
    },
    validate(event) {
      if (event) {
        if (event.target) {
          /* Antes de intentar registrar por primera vez, no validamos */
          if (!this.noValidar) {
            this.$v.$touch();
            this.displayErrores(event.target.id);
          }
        }
      } 
    },
    displayErrores(field) {
      var validarTodos = false;
      var campo = '';

      if (field) {
        campo = field;
      } else {
        validarTodos = true;
      }

      if (campo == 'titulo' || validarTodos) {
        if (this.$v.titulo.$error) {
          this.tituloValido = false
          if (!this.$v.titulo.required) {
            this.tituloInvalidoMsj = 'Debe ingresar el título de la propiedad';
          } else if (!this.$v.titulo.maxLength) {
            this.tituloInvalidoMsj = 'El título solo puede contener hasta 150 caracteres';
          }      
        } else {
          this.tituloValido = null;
          this.tituloInvalidoMsj = '';
        }
      }

      if (campo == 'descripcion' || validarTodos) {
        if (this.$v.descripcion.$error) {
          this.descripcionValida = false
          if (!this.$v.descripcion.maxLength) {
            this.descripcionInvalidaMsj = 'La descripción solo puede contener hasta 500 caracteres';
          }      
        } else {
          this.descripcionValida = null;
          this.descripcionInvalidaMsj = '';
        }
      }

      if (campo == 'direccion' || validarTodos) {
        if (this.$v.direccion.$error) {
          this.direccionValida = false
          if (!this.$v.direccion.required) {
            this.direccionInvalidaMsj = 'Debe ingresar la dirección de la propiedad';
          } else if (!this.$v.direccion.maxLength) {
            this.direccionInvalidaMsj = 'La dirección solo puede contener hasta 250 caracteres';
          }      
        } else {
          this.direccionValida = null;
          this.direccionInvalidaMsj = '';
        }
      }

      if (campo == 'localidad' || validarTodos) {
        if (this.$v.localidad.$error) {
          this.localidadValida = false
          if (!this.$v.localidad.required) {
            this.localidadInvalidaMsj = 'Debe ingresar la localidad de la propiedad';
          } else if (!this.$v.localidad.maxLength) {
            this.localidadInvalidaMsj = 'La localidad solo puede contener hasta 250 caracteres';
          }      
        } else {
          this.localidadValida = null;
          this.localidadInvalidaMsj = '';
        }
      }

      if (campo == 'barrio' || validarTodos) {
        if (this.$v.barrio.$error) {
          this.barrioValido = false
          if (!this.$v.barrio.required) {
            this.barrioInvalidoMsj = 'Debe ingresar el barrio de la propiedad';
          } else if (!this.$v.barrio.maxLength) {
            this.barrioInvalidoMsj = 'El barrio solo puede contener hasta 250 caracteres';
          }      
        } else {
          this.barrioValido = null;
          this.barrioInvalidoMsj = '';
        }
      }

      if (campo == 'locationLat' || validarTodos) {
        if (this.$v.locationLat.$error) {
          this.locationLatValida = false
          // if (!this.$v.locationLat.decimal) {
          //   this.locationLatInvalidaMsj = 'La latitud de la ubicación ingresada no es válida.';
          // }      
        } else {
          this.locationLatValida = null;
          // this.locationLatInvalidaMsj = '';
        }
      }

      if (campo == 'locationLng' || validarTodos) {
        if (this.$v.locationLng.$error) {
          this.locationLngValida = false
          // if (!this.$v.locationLng.decimal) {
          //   this.locationLngInvalidaMsj = 'La longitud de la ubicación ingresada no es válida.';
          // }      
        } else {
          this.locationLngValida = null;
          // this.locationLngInvalidaMsj = '';
        }
      }
      
      this.locationInvalidaMsj = '';

      if (this.locationLatValida == false & this.locationLngValida == false)
        this.locationInvalidaMsj = 'La latitud y longitud de la ubicación ingresadas no son válidas.';
      else if (this.locationLatValida == false)
        this.locationInvalidaMsj = 'La latitud de la ubicación ingresada no es válida.';
      else if (this.locationLngValida == false)
        this.locationInvalidaMsj = 'La longitud de la ubicación ingresada no es válida.';

      if (campo == 'superficieCubierta' || validarTodos) {
        if (this.$v.superficieCubierta.$error) {
          this.superficieCubiertaValida = false
          if (!this.$v.superficieCubierta.numeric || !this.$v.superficieCubierta.minValue) {
            this.superficieCubiertaInvalidaMsj = 'La superficie cubierta ingresada no es válida.';
          }
        } else {
          this.superficieCubiertaValida = null;
          this.superficieCubiertaInvalidaMsj = '';
        }
      }

      if (campo == 'superficieTotal' || validarTodos) {
        if (this.$v.superficieTotal.$error) {
          this.superficieTotalValida = false
          if (!this.$v.superficieTotal.numeric || !this.$v.superficieTotal.minValue) {
            this.superficieTotalInvalidaMsj = 'La superficie total ingresada no es válida.';
          }
        } else {
          this.superficieTotalValida = null;
          this.superficieTotalInvalidaMsj = '';
        }
      }

      if (campo == 'antiguedad' || validarTodos) {
        if (this.$v.antiguedad.$error) {
          this.antiguedadValida = false
          if (!this.$v.antiguedad.numeric || !this.$v.antiguedad.minValue) {
            this.antiguedadInvalidaMsj = 'La antigüedad ingresada no es válida.';
          }
        } else {
          this.antiguedadValida = null;
          this.antiguedadInvalidaMsj = '';
        }
      }

      if (campo == 'ambientes' || validarTodos) {
        if (this.$v.ambientes.$error) {
          this.ambientesValidos = false
          if (!this.$v.ambientes.numeric || !this.$v.ambientes.minValue) {
            this.ambientesInvalidosMsj = 'La cantidad de ambientes ingresada no es válida.';
          }
        } else {
          this.ambientesValidos = null;
          this.ambientesInvalidosMsj = '';
        }
      }

      if (campo == 'dormitorios' || validarTodos) {
        if (this.$v.dormitorios.$error) {
          this.dormitoriosValidos = false
          if (!this.$v.dormitorios.numeric || !this.$v.dormitorios.minValue) {
            this.dormitoriosInvalidosMsj = 'La cantidad de dormitorios ingresada no es válida.';
          }
        } else {
          this.dormitoriosValidos = null;
          this.dormitoriosInvalidosMsj = '';
        }
      }

      if (campo == 'banios' || validarTodos) {
        if (this.$v.banios.$error) {
          this.baniosValidos = false
          if (!this.$v.banios.numeric || !this.$v.banios.minValue) {
            this.baniosInvalidosMsj = 'La cantidad de baños ingresada no es válida.';
          }
        } else {
          this.baniosValidos = null;
          this.baniosInvalidosMsj = '';
        }
      }

      if (campo == 'monedaVenta' || validarTodos) {
        if (this.$v.monedaVenta.$error) {
          this.monedaVentaValida = false;
          // if (!this.$v.monedaVenta.required) {
          // }
        } else {
          this.monedaVentaValida = null;
        }
      }

      if (campo == 'precioVenta' || validarTodos) {
        if (this.$v.precioVenta.$error) {
          this.precioVentaValido = false
          // if (!this.$v.precioVenta.decimal) {
          //   this.precioVentaInvalidoMsj = 'El precio de venta ingresado no es válido.';
          // }      
        } else {
          this.precioVentaValido = null;
        }
      }

      this.precioVentaInvalidoMsj = '';
      if (this.monedaVentaValida == false) {
        if (this.precioVentaValido == null) {
          this.precioVentaInvalidoMsj = 'Seleccione la moneda correspondiente a la operación';
        } else {
          this.precioVentaInvalidoMsj = 'Debe ingresar la moneda y el precio de venta.';
        }
      } else {
        if (this.precioVentaValido == false) {
          this.precioVentaInvalidoMsj = 'El precio de venta ingresado no es válido.';
        }
      }

      if (campo == 'monedaAlquiler' || validarTodos) {
        if (this.$v.monedaAlquiler.$error) {
          this.monedaAlquilerValida = false
        } else {
          this.monedaAlquilerValida = null;
        }
      }

      if (campo == 'precioAlquiler' || validarTodos) {
        if (this.$v.precioAlquiler.$error) {
          this.precioAlquilerValido = false
        } else {
          this.precioAlquilerValido = null;
        }
      }

      this.precioAlquilerInvalidoMsj = '';
      if (this.monedaAlquilerValida == false) {
        if (this.precioAlquilerValido == null) {
          this.precioAlquilerInvalidoMsj = 'Seleccione la moneda correspondiente a la operación';
        } else {
          this.precioAlquilerInvalidoMsj = 'Debe ingresar la moneda y el precio de alquiler.';
        }
      } else {
        if (this.precioAlquilerValido == false) {
          this.precioAlquilerInvalidoMsj = 'El precio de alquiler ingresado no es válido.';
        }
      }

      if (campo == 'expensas' || validarTodos) {
        if (this.$v.expensas.$error) {
          this.expensasValidas = false
          if (!this.$v.expensas.decimal) {
            this.expensasInvalidasMsj = 'El precio de expensas ingresado no es válido.';
          }      
        } else {
          this.expensasValidas = null;
          this.expensasInvalidasMsj = '';
        }
      }

    },
    save() {
      this.noValidar = false;

      let tipoPropiedad;
      if (this.tipoPropiedadNombre) {
        if (this.tipoPropiedadNombre != '') {
          tipoPropiedad = this.tiposPropiedades.find(tipoPropiedad => tipoPropiedad.nombre.trim() == this.tipoPropiedadNombre);
          if (tipoPropiedad) {
            this.$store.dispatch('propiedad/setTipoPropiedad', tipoPropiedad);
          }
        }
      }

      let save = true;

      let tipoPropiedadError = true;
      if (this.tipoPropiedad) {
        if (this.tipoPropiedad.id) {
          if (this.tipoPropiedad.id != 0) {
            tipoPropiedadError = false;
          }
        }
      }

      if (tipoPropiedadError) {
        save = false;
        this.tipoPropiedadValido = false;
        this.tipoPropiedadInvalidoMsj = 'Debe seleccionar el tipo de propiedad';
      }

      this.$v.$touch();

      if (this.$v.$anyError) {
        this.displayErrores();
      } else {
        if (save) {
          this.showSpinner();
          this.$store.dispatch('propiedad/save')
            .then( () => this.showModalMsj ('Los cambios se registraron con éxito.', this.goBack, this.goBack, 'light'))
            .catch( error => this.showErrorMsj (error))
            .finally(() => this.hideSpinner());
        }
      }
    },
    tipoPropiedadActualizado(tipoPropiedadNombre) {
      this.tipoPropiedadNombre = tipoPropiedadNombre;
      if (this.tipoPropiedadNombre) {
        this.tipoPropiedadValido = null;
        this.tipoPropiedadInvalidoMsj = '';
      }
    },
    goBack() {
      this.$router.push({ name: 'Propiedades' });
      // this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/users'})
    },
    monedaVentaActualizada(monedaVenta) {
      this.monedaVentaValida = null;
    },
    monedaAlquilerActualizada(monedaAlquiler) {
      this.monedaAlquilerValida = null;
    },
    actualizarCaracteristica(item) {
      let caracteristica = this.caracteristicas.find(caracteristica => caracteristica.nombre.trim() == item.nombre.trim());
      if (caracteristica) {
        caracteristica.checked = !caracteristica.checked;
      }
    }
  },
  validations() {
    return {
      titulo: {
        required,
        maxLength: maxLength(150)
      },
	    descripcion: {
        maxLength: maxLength(500)
      },
	    direccion: {
        required,
        maxLength: maxLength(250)
      },
	    localidad: {
        required,
        maxLength: maxLength(250)
      },
	    barrio: {
        required,
        maxLength: maxLength(250)
      },
	    locationLat: {
        decimal
      },
      locationLng: {
        decimal
      },
	    superficieTotal: {
        numeric,
        minValue: minValue(0),
      },
	    superficieCubierta: {
        numeric,
        minValue: minValue(0),
      },
	    antiguedad: {
        numeric,
        minValue: minValue(0),
      },
	    ambientes: {
        numeric,
        minValue: minValue(0),
      },
	    dormitorios: {
        numeric,
        minValue: minValue(0),
      },
	    banios: {
        numeric,
        minValue: minValue(0),
      },
	    monedaVenta: {
        required: requiredIf(function () {
          let isRequired = false;

          if (this.precioVenta != 0) {
            isRequired = true;
          }

          return isRequired;
        }),
      },
	    precioVenta: {
        decimal,
        minValue: minValue(0),
      },
	    monedaAlquiler: {
        required: requiredIf(function () {
          let isRequired = false;

          if (this.precioAlquiler != 0) {
            isRequired = true;
          }

          return isRequired;
        }),
      },
	    precioAlquiler: {
        decimal,
        minValue: minValue(0),
      },
	    expensas: {
        decimal,
        minValue: minValue(0),
      },
    }
  }
}
</script>
